@import "fonts";
.menu--desktop-wrapper {
  margin-top: 80px;
}
@media only screen and (max-width: 1320px) and (min-width: 1024px) {
  .sticky--header {
    height: 115px;
  }

  .header-right .display-row {
    margin-top: 10px !important;
  }

  .menu--desktop-wrapper li {
    margin-right: 20px;
    font-size: 1.125rem;
  }
  .menu--desktop-logo {
    max-height: none !important;
  }
}
